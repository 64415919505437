import React from "react"
import Seo from "../components/Seo"
const contact = () => {
  return (
    <>
      <Seo title="Contact" />
      <section className="contact-page">
        <article className="contact-form">
          <h3>get in touch</h3>
          <p className="form-text">
            Please use the form below to send any inquiries, or to schedule a
            discovery session with InfoArc. We try to respond as quickly as
            humanly possible, however, please allow at least 12 hours for
            responses.
          </p>
          <form
            name="contact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact" />
            <div class="form-group">
              <input
                type="text"
                name="MERGE1"
                id="MERGE1"
                placeholder="first name"
                className="form-control"
              />
              <input
                type="text"
                name="MERGE2"
                id="MERGE1"
                placeholder="last name"
                className="form-control"
              />
              <input
                type="email"
                name="MERGE0"
                id="MERGE0"
                placeholder="email"
                className="form-control"
              />
              <textarea
                name="MERGE6"
                id="MERGE6"
                rows="5"
                placeholder="message"
                className="form-control"
              ></textarea>
            </div>
            <button type="submit" className="submit-btn btn">
              submit here
            </button>
          </form>
        </article>
      </section>
    </>
  )
}

export default contact
